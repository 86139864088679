import styles from './Card.module.scss'

export const Card = ({
  title,
  imgSrc,
  description
                     }) => {
  return (
    <div className={styles.card}>
      <h5 >{title}</h5>
      <img src={imgSrc} alt=""/>
      <p>{description}</p>
    </div>
  )
}
