import React, { useState } from 'react'
import styles from './index.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import QrItem from "./QrItem";

const QrCodeList = ({ mobile = false, businesses }) => {
  const [ showQR, setShowQR ] = useState('')

  if (!businesses) {
    return null
  }

  return (
    <div className={mobile ? styles.qr_codes_section_mobile : styles.qr_codes_section}>
      {
        mobile ? (
          <Swiper
            spaceBetween={mobile ? 0 : 30}
            slidesPerView={1}
            slidesPerColumn={1}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {
              businesses?.map((item, index) => {
                return index < 4 && (
                  <SwiperSlide key={index}>
                    <QrItem
                      item={item}
                      setShowQR={setShowQR}
                      showQR={showQR}
                      index={index}
                      qrSize={180}
                      text='tap to visit'
                      mobile={true}
                    />
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
          ) : (
          businesses?.map((item, index) => {
            return index < 4 && (
                <QrItem item={item} setShowQR={setShowQR} showQR={showQR} index={index}/>
              )
          })
        )

      }
    </div>
  )
}

export default QrCodeList
