import React, {useEffect, useState} from 'react'
import { videoLinkHelper} from "../../helpers/videoLinkHelper";

const FeaturedBusiness = ({ useBlock, featuredBusiness, position = 'left', background = false }) => {

  const [featured, setFeatured] = useState()
  
  useEffect(() => {
    const lastFeaturedBusiness = featuredBusiness?.filter(item => item?.block === useBlock)?.[0]
    
    if (!lastFeaturedBusiness) {
      return
    }

    setFeatured({...lastFeaturedBusiness, videoUrl: lastFeaturedBusiness?.videoUrl.replace('watch?v=', 'embed/') })
  }, [featuredBusiness])

  if (!featured) {
    return null
  }

  const youtubeVideoId =  videoLinkHelper(featured.videoUrl)

  return position === 'left' ? (
    <section className="video-section content-container" style={background ? { background: '#F7F5FF'} : {}}>
      <div className="video">
        <iframe
          src={youtubeVideoId ? 'https://www.youtube.com/embed/' + youtubeVideoId :  featured.videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen/>
      </div>
      <div className="video-text">
        <h2 className="video-title">{featured.name}</h2>
        <p className="video-description">
          {featured.text}
        </p>
      </div>
    </section>
  ) : (
    <section className="video-section content-container video-section_right" style={background ? { background: '#F7F5FF'} : {}}>
      <div className="video-text video-text_padding">
        <h2 className="video-title">{featured.name}</h2>
        <p className="video-description video-description_left">
          {featured.text}
        </p>
      </div>
      <div className="video">
        <iframe
          src={youtubeVideoId ? 'https://www.youtube.com/embed/' + youtubeVideoId :  featured.videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen/>
      </div>
    </section>
  )
};

export default FeaturedBusiness;
