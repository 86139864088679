import React from 'react'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup'
import { useMutation, gql } from "@apollo/client";
import rewardsClient from '../../apollo/rewards-apollo-client'

const SUBSCRIBE_TO_NEWS_LETTER = gql`
    mutation subscribeToNewsletter($input: SubscribeNewsLetterInput!) {
        subscribeToNewsletter (input: $input)
    }
`

const SubscriptionForm = () => {

  const [ subscribeMutation, { loading, error, data } ] = useMutation(SUBSCRIBE_TO_NEWS_LETTER, {
    client: rewardsClient
  })

  const onSubmit = async (values) => {
    await subscribeMutation({
      variables: {
        input: {
          newsletterTypeId: values.newsletterTypeId,
          email: values.email
        }
      }
    }).then(res => {
      const result = res?.data?.subscribeToNewsletter
      if (result) {
        alert(result === 'Subscribed' ? 'You have successfully subscribed' : 'You are already subscribed')
      }
    }).catch(e => {
      if (e) {
        alert('Error occurred')
      }
    })
  }

  return (
    <Formik
      initialValues={{
        email: '',
        newsletterTypeId: 3,
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
      })}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="subscribe__form">
          <div role="group" aria-labelledby="my-radio-group" className="subscribe__radio-container">
            <div className="radio-button">
              <label>
                <Field
                  type="radio"
                  name="newsletterTypeId"
                  value={3}
                  checked={values.newsletterTypeId === 3}
                  onChange={() => setFieldValue('newsletterTypeId', 3)
                }
                />
                I'm a Business
              </label>
            </div>
            <div className="radio-button">
              <label>
                <Field
                  type="radio"
                  name="newsletterTypeId"
                  value={2}
                  checked={values.newsletterTypeId === 2}
                  onChange={() => setFieldValue('newsletterTypeId', 2)}
                />
                I'm a Customer
              </label>
            </div>
          </div>
          <div className="subscribe__radio-container">
            <Field
              id="email"
              name="email"
              placeholder="Email address*"
              className="subscribe__input data-hj-allow"
              type="email"
            />
            <button className="submit-btn" disabled={loading}>Subscribe</button>
          </div>
        </Form>
      )}
    </Formik>
  )
};

export default SubscriptionForm;
