import React from 'react'
import { REWARDS_CDN_URL } from '../../apollo/rewards-apollo-client'
import styles from './index.module.scss'
import cn from 'classnames'
import QRCode from "react-qr-code";
import { useRouter } from 'next/router'

const QrItem = ({ item, showQR, setShowQR, qrSize = 200, text = 'click to reveal', mobile = false }) => {
  const router = useRouter()
  const isClicked = item.id === showQR

  return (
    <div className={styles.qr_container} key={item.id}
         onClick={() =>
            mobile ?  router.push(item.branchUrl) : setShowQR(isClicked ? '' : item.id)}
    >
      <span className={cn(styles.qr_blur, isClicked ? styles.qr_blur_hide : '')}>
        <QRCode value={item.branchUrl} size={qrSize} />
      </span>

      <div className={styles.qr_text_container}>
        <img src="/scan_icon.svg" alt=""/>
        <p className={styles.qr_text} >
          {text}
        </p>
      </div>
      <img src={`${REWARDS_CDN_URL}${item?.logoUrl}`} alt="Business Logo" className={cn(styles.qr_business_logo, isClicked && styles.qr_business_logo_hide)}/>
    </div>
  )
}

export default QrItem
