import React from 'react'
import { Card } from '../components/Card/Card'
import SwiperCore, { Navigation, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import Head from 'next/head'
import FeaturedBusiness from '../components/FeaturedBusiness/FeaturedBusiness'
import {APPLE_STORE_URL, FACEBOOK_URL, GOOGLE_STORE_URL, INSTAGRAM_URL, LINKEDIN_URL} from '../components/constants'
import SubscriptionForm from '../components/SubscriptionForm/SubscriptionForm'
import Rewards from '../components/Rewards/Rewards'
import QqCodeList from '../components/QqCodeList'
import rewardsClient from '../apollo/rewards-apollo-client'
import client from '../apollo/apollo-client'
import { REWARDS_QUERY } from '../apollo/query/businesses_rewards_query'
import { INDEX_PAGE_QUERY } from '../apollo/query/index_page_query'
import rewardsData from '../public/files/rewards.json'
import * as Sentry from '@sentry/nextjs'

SwiperCore.use([Navigation, Pagination])

const howToEarn = [
  {
    title: 'Join',
    imgSrc: '/card_join.svg',
    description: 'Get welcome points when you join'
  },
  {
    title: 'Buy',
    imgSrc: '/card_buy.svg',
    description: 'Get recognised and get points for shopping'
  },
  {
    title: 'Refer',
    imgSrc: '/card_refer.svg',
    description: 'Receive points for referring friends and family'
  },
];

const howItWork = [
  {
    title: 'Customer Reviews',
    imgSrc: '/customer_reviews.svg',
    description: 'We help you get more Google Ratings from customers. This attracts new customers and improves your SEO.'
  },
  {
    title: 'Retention & Loyalty',
    imgSrc: '/retention_loyalty.svg',
    description: 'We build you a program to reward customers. This helps to have new customers and existing customers coming back and spending more.'
  },
  {
    title: 'Customer Referrals',
    imgSrc: '/customer_referrals_illustration.svg',
    description: 'We give your business a way to attract new customers through referrals. This helps reduce your marketing costs and is exciting for your customers.'
  },
];

export async function getServerSideProps (context) {
  try {
    const { data, error: internalRewardsError } = await client.query({
      query: INDEX_PAGE_QUERY,
      variables: {
        where: {
          pageSlug: context.resolvedUrl
        }
      },
      fetchPolicy: 'no-cache'
    })

    const allFeaturedRewards = data?.allFeaturedRewards
    const randomNum = Math.floor(Math.random() * (allFeaturedRewards.length - 1))
    const randomBusinessId = allFeaturedRewards?.[randomNum]

    const firstQr = data?.allMainPageQrBusinesses?.[0]?.businessId || randomBusinessId?.businessId

    const { data: reward, error } = await rewardsClient.query({
      query: REWARDS_QUERY,
      variables: {
        id1: +data?.allMainPageQrBusinesses?.[0]?.businessId || +firstQr,
        id2: +data?.allMainPageQrBusinesses?.[1]?.businessId || +firstQr,
        id3: +data?.allMainPageQrBusinesses?.[2]?.businessId || firstQr,
        id4: +data?.allMainPageQrBusinesses?.[3]?.businessId || firstQr,
        id: +randomBusinessId?.businessId,
        reward1: +allFeaturedRewards?.[0]?.businessId || +firstQr,
        reward2: +allFeaturedRewards?.[1]?.businessId || +firstQr,
        reward3: +allFeaturedRewards?.[2]?.businessId || +firstQr,
      },
      fetchPolicy: 'no-cache'
    })

    return { props: {
        rewards: reward || [],
        internalRewards: [reward.reward1, reward.reward2, reward.reward3],
        featuredBusiness: data.allFeaturedBusinesses,
        allTextPage: data.allTextPages,
        businessQR:  data.allMainPageQrBusinesses,
        error: error || false,
        internalRewardsError: internalRewardsError || '',
        allMetaTag: data.allMetaTags,
        businesses: [reward.business1, reward.business2, reward.business3, reward.business4],
        allBlogs: data?.allBlogs,
      } };

  }  catch (error) {
    Sentry.captureException(error)
    console.log('error', error)
    return { props: {
        rewards: [],
        internalRewards: [],
        featuredBusiness: [],
        allTextPage: [],
        businessQR: [],
        error: true,
        internalRewardsError: true,
        allMetaTag: [],
        businesses: [],
        allBlogs: [],
      } };
  }
}

function Home (props) {
  // const metaTag = props.allMetaTag?.[0]

  const internalRewardsData = [
    {
      "businessId": 141,
      "id": "1"
    }
  ]

  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/fonts/SFUIDisplay-Regular.ttf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/SFUIDisplay-Heavy.ttf"
          as="font"
          crossOrigin=""
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#ffffff" />
        <title>yesloyal</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="facebook-domain-verification" content="s9lym0w29toefd8h93t1l842a6j1yk" />
      </Head>
      <main>
        <section className="main_banner container">
          <Navbar allTextPage={props.allTextPage} allBlogs={props.allBlogs} />
          <div className="content">
            <div className="banner_title">
              <h1 className="main_banner__heads main_banner__heads--size">Loyalty <br/> Rewarded</h1>
              <h1 className="main_banner__heads main_banner__heads--size">Recognised</h1>
              <p className="main_banner__description main_banner__description--size">
                Receive exclusive invitations to join businesses that truly value customer loyalty
              </p>

              <div className="download-link-container">
                <a href={APPLE_STORE_URL} className="download-link">
                  <img src="/app_store.svg" alt="" />
                </a>
                <a href={GOOGLE_STORE_URL} className="download-link">
                  <img src="/google_play.svg" alt="" />
                </a>
              </div>
              {/*<div className="download-link-container download-link-container_mobile">*/}
              {/*  <a href='https://forms.clickup.com/f/6jran-15302/FECM65LQW2MZ9GSUBQ' className="download-button">*/}
              {/*    Start a free trial*/}
              {/*  </a>*/}
              {/*  <a href='https://tidycal.com/yesloyal' className="download-button download-button_transparent">*/}
              {/*    Book a demo*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>

            <Rewards rewards={!props.error ?  props.rewards : rewardsData } businesses={props.internalRewards.length ? props.internalRewards : internalRewardsData} />

            <div className="banner_social_button">
              <a className="footer_link-social" href={INSTAGRAM_URL}>
                <img src="/inst.svg" alt="Instagram"/>
              </a>
              {/*<a className="footer_link-social" href="#">*/}
              {/*  <img src="/twitter.svg" alt=""/>*/}
              {/*</a>*/}
              <a className="footer_link-social" href={LINKEDIN_URL}>
                <img src="/linkedin.svg" alt="Linkedin"/>
              </a>
              <a className="footer_link-social" href={FACEBOOK_URL}>
                <img src="/facebook.svg" alt="Facebook"/>
              </a>
              <a className="footer_link-social" href='mailto:info@yesloyal.com'>
                <img src="/mail_icon.svg" alt="Mail"/>
              </a>
            </div>
          </div>
        </section>

        <FeaturedBusiness useBlock={1} featuredBusiness={props.featuredBusiness} />

        <section className="how-it-work-section content-container">
          <h1 className="how-it-work-title">
            How we Help
          </h1>
          <p className="how-it-work-subtitle">
            We understand that your business is unique and we take a
            tailored approach to every engagement.
          </p>
          <p className="how-it-work-subtitle">
            We put our reviews, retention, loyalty and referral technology
            alongside your current business systems and processes to
            supercharge your business!
          </p>
          <div className="card-section-mobile">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              slidesPerColumn={1}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {
                howItWork.map(({title, imgSrc, description}, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Card title={title} imgSrc={imgSrc} description={description}/>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
          <div className="card-section">
            {
              howItWork.map(({title, imgSrc, description}, index) => {
                return (
                  <Card title={title} imgSrc={imgSrc} description={description} key={index}/>
                )
              })
            }
          </div>
        </section>

        <section className="qr_section content-container">

          <QqCodeList mobile={true} businesses={props.businesses} />

          <QqCodeList businesses={props.businesses} />

          <div className="qr-section-description">
            <h1 className="qr-title">
              See how yesloyal's technology is working for businesses just like yours!
            </h1>
            <p className="qr-description">
              Download the App today and scan QR codes from some of our Featured Businesses
            </p>
            <div className="download-link-container">
              <a href={APPLE_STORE_URL} className="download-link">
                <img src="/app_store.svg" alt="" />
              </a>
              <a href={GOOGLE_STORE_URL} className="download-link">
                <img src="/google_play.svg" alt="" />
              </a>
            </div>
          </div>
        </section>

        <FeaturedBusiness
          useBlock={2}
          position='right'
          featuredBusiness={props.featuredBusiness}
          background={true}
        />

        <section className="how_to_earn content-container">
          <h1 className="how_to_earn__title">Earning Points</h1>
          <p className="how_to_earn__description">
            Here are some of the ways points can be earned at yesloyal powered businesses.
          </p>
          {/*<p className="how_to_earn__description">*/}
          {/*  Note: points earned with a business Stay with the loyal business you earned them from*/}
          {/*</p>*/}
          <div className="how_to_earn_cards_mobile">
            <Swiper
              slidesPerColumn={1}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
            >
              {
                howToEarn.map(({title, imgSrc, description}, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Card title={title} imgSrc={imgSrc} description={description}/>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
          <div className="how_to_earn_cards">
            {
              howToEarn.map(({title, imgSrc, description}, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Card title={title} imgSrc={imgSrc} description={description}/>
                  </SwiperSlide>
                )
              })
            }
          </div>
        </section>

        <FeaturedBusiness
          useBlock={3}
          featuredBusiness={props.featuredBusiness}
          background={true}
        />

        <section className="subscribe content-container">
          <p className="subscribe__title">
            Subscribe to our Newsletter for all the ways that Loyalty can be more rewarding for you
          </p>
          <SubscriptionForm />
        </section>

        <FeaturedBusiness
          useBlock={4}
          featuredBusiness={props.featuredBusiness}
          background={true}
          position='right'
        />
      </main>
      <Footer allTextPage={props.allTextPage} allBlogs={props.allBlogs}/>
    </>
  )
}

export default Home

